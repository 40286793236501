<script>
  export let dataCy = {};

  export let id;
  export let value;
  export let placeholder;
  export let display = 'block';
  export let extraClasses = [];
</script>

<input bind:this={dataCy.input} {id}
  class="{extraClasses.join(' ')}"
  type="text" style:display bind:value {placeholder}
  on:input/>
