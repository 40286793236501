<script>
  import { revConfig, currentProductReviewData, tydal } from '../../../config/storage';
  import { translations } from '../../../config/translations';
  import { visit } from '../../navigation';
  import MediaForm from './MediaForm.svelte';

  function closeModal(){
    window.top.postMessage({msg_action: "ba.ratings.modal.close", msg_options: {}}, '*');
  }

  const product = $currentProductReviewData.product;
  const standaloneMode = $tydal.rev_order_id;

  function saveRating(event){
    event.preventDefault();
    let starRating= event.currentTarget.dataset.starRating;
    currentProductReviewData.update((data) => {
      return {
        ...data,
        star_rating: starRating
      };
    });

    visit(MediaForm);
  }
</script>

<div id="review-wizard-modal">
  <div class="wizard-slide" id="wizard-rating">
    {#if !standaloneMode}
      <button type="button" class="rev-close-modal" on:click={closeModal}></button>
    {/if}
    <div class="wizard-body">
      <h3 class="title">{$translations.modal_header}</h3>
      <div class="rate-product-stars">
        {#each Array(5) as _, i}
          <div class="review-star-container">
            <a href="{'javascript:void(0)'}" data-star-rating={i + 1} on:click={saveRating}>
              <svg id="ba-review-star{i + 1}" data-data="{i + 1}" enable-background="new 0 0 24 24" height="48" width="48" fill="{$revConfig.widget_css.theme_star_color}" viewBox="3.44 3.57 17.11 16.36"><path d="m0 0h24v24h-24z" fill="none"/>
                {#if $revConfig.widget_settings.display_star_fill_type == "empty"}
                  <path d="m0 0h24v24h-24z" fill="none"></path><path d="m19.65 9.04-4.84-.42-1.89-4.45c-.34-.81-1.5-.81-1.84 0l-1.89 4.46-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.73 3.67-3.18c.67-.58.32-1.68-.56-1.75zm-7.65 6.36-3.76 2.27 1-4.28-3.32-2.88 4.38-.38 1.7-4.03 1.71 4.04 4.38.38-3.32 2.88 1 4.28z"></path>
                {:else}
                  <path d="m0 0h24v24h-24z" fill="none"/><path d="m12 17.27 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72 3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41-1.89-4.46c-.34-.81-1.5-.81-1.84 0l-1.89 4.45-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08z"/>
                {/if}
              </svg>
            </a>
            <span>
              {#if $revConfig.widget_settings.display_rating_text_type == "numbers"}
                {i + 1}
              {:else}
                {[1,3,5].includes(i + 1) ? $translations[`star${i + 1}_caption`] : ''}
              {/if}
            </span>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>