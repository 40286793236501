<script>
  export let dataCy = {};

  export let id;
  export let extra_class;
</script>

<div bind:this={dataCy.text} {id} class="message error-text {extra_class}">
  <slot/>
</div>
