<script>
  import { onMount } from 'svelte';
  import { fly } from 'svelte/transition';
  import { currentProductReviewData } from '../../../config/storage';
  import TextArea from '../../components/TextArea.svelte';
  import ErrorText from '../../components/ErrorText.svelte';
  import { translations } from '../../../config/translations';
  import { visit } from '../../navigation';
  import TitleForm from './TitleForm.svelte';
  import MediaForm from './MediaForm.svelte';

  function saveRating(event){
    event.preventDefault();
    if (ratingBody.length == 0) {
      ratingBodyError = $translations.required_field;
      return;
    }
    currentProductReviewData.update((data) => {
      return {
        ...data,
        rating_body: ratingBody
      };
    });

    visit(TitleForm)
  }

  let transitionOptions = { x: 200, duration: 500 }
  export let slideBack = false;
  if (slideBack){
    transitionOptions.x = -200;
  }

  onMount(() => {
    document.getElementById("rating_body").focus();
  });

  let ratingBody= $currentProductReviewData.rating_body || '';
  let ratingBodyError;
</script>

<div class="wizard-slide" id="wizard-body">
  <div class="wizard-body" in:fly="{transitionOptions}">
    <h3 class="title">{$translations.tell_us_more}</h3>
    <div class="review-body-form">
      <TextArea id="rating_body"
        extraClasses={['review-text', 'rev-input', 'rev-textarea', ratingBodyError ? 'error' : '']}
        placeholder="{$translations.share_your_experience}"
        display="block" bind:value={ratingBody}
        on:input={() => { ratingBodyError = undefined }}
      />
      {#if ratingBodyError}
        <ErrorText id="rating_body_error">{ratingBodyError}</ErrorText>
      {/if}
    </div>
    <button type="button" on:click={saveRating} class="button rev-button">{$translations.next} ❯</button>
  </div>
  <div class="wizard-footer">
    <button type="button" on:click={() => visit(MediaForm, {slideBack: true})} class="back bg-none">❮ {$translations.back}</button>
  </div>
</div>