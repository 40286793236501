import { $revConfig, $tydal } from './rev/config/storage';
import { _currency_sign } from './util';

export function discountAmountText(){
  const shopCurrencySign = _currency_sign($tydal.common.shop.money_format);
  let discountAmount = '';
  if ($revConfig.discount_info.discount_type == "percentage") {
    discountAmount = `${$revConfig.discount_info.discount_amount}%`;
  } else if ($revConfig.discount_info.discount_type == "fixed_amount"){
    discountAmount = `${shopCurrencySign}${$revConfig.discount_info.discount_amount}`
  }
  return discountAmount;
}