import { v4 as uuidv4 } from 'uuid';

import { currentProductReviewData, $currentProductReviewData } from '../config/storage';
import { $translations } from '../config/translations';

export class SaveRatingError extends Error {
  constructor(message, field) {
    super(message);
    this.name = this.constructor.name;
    this.field = field;
  }
}

export async function saveRating({ api, draft = false } = {}) {
  if (!draft && !$currentProductReviewData.first_name) {
    throw new SaveRatingError($translations.required_field, 'first_name');
  }

  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
  if (!draft && (!$currentProductReviewData.email || !$currentProductReviewData.email.match(mailformat))) {
    throw new SaveRatingError($translations.invalid_email, 'email');
  }

  if (!$currentProductReviewData.draft_key) {
    currentProductReviewData.update((data) => {
      return {
        ...data,
        draft_key: uuidv4()
      };
    });
  }

  const result = await api.create_review({
    title: $currentProductReviewData.rating_title,
    body: $currentProductReviewData.rating_body,
    first_name: $currentProductReviewData.first_name,
    last_name: $currentProductReviewData.last_name,
    stars: $currentProductReviewData.star_rating,
    email: $currentProductReviewData.email,
    product_id: $currentProductReviewData.product.id,
    product_title: $currentProductReviewData.product.title,
    product_handle: $currentProductReviewData.product.handle,
    visitor_token: window.parent.tyMet?.getVisitorToken(),
    image: $currentProductReviewData.image_file,
    cloudflare_stream_uid: $currentProductReviewData.cloudflare_stream_uid,
    draft,
    draft_key: $currentProductReviewData.draft_key
  });

  if (!result.success) {
    if (result.errors?.email) {
      throw new SaveRatingError($translations.email_already_registered);
    } else {
      throw new SaveRatingError($translations.error_submitting_review);
    }
  }

  currentProductReviewData.update((data) => {
    return {
      ...data,
      discount_code: result.discount_code
    };
  });
}
