<script>
  export let dataCy = {};

  export let id;
  export let value;
  export let placeholder;
  export let display = 'block';
  export let extraClasses = [];
</script>

<textarea bind:this={dataCy.input} {id}
  class="txt-field full-width {extraClasses.join(' ')}"
  tabindex="-1" style:display bind:value {placeholder}
  on:input></textarea>
