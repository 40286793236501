import {buildAPIClient} from '../../api';
import { initializeStorage, $revConfig } from '../../rev/config/storage';
import { resetPageStack, visit } from '../../rev/modal/navigation';

import App from '../../rev/modal/App';
import EmptyPage from '../../rev/modal/pages/EmptyPage';
import ReviewQuickViewPage from '../../rev/modal/pages/ReviewQuickViewPage';
import RatingForm from '../../rev/modal/pages/WizardPage/RatingForm.svelte';
import MediaForm from '../../rev/modal/pages/WizardPage/MediaForm.svelte';
import BodyForm from '../../rev/modal/pages/WizardPage/BodyForm.svelte';
import TitleForm from '../../rev/modal/pages/WizardPage/TitleForm.svelte';
import AuthorForm from '../../rev/modal/pages/WizardPage/AuthorForm.svelte';
import SuccessPage from '../../rev/modal/pages/WizardPage/SuccessPage.svelte';

(function(){
  if(typeof String.prototype.replaceAll == "undefined") {
    String.prototype.replaceAll = function(match, replace){
      return this.replace(new RegExp(match, "g"), () => replace);
    }
  }

  function openPageOrEmpty(e, page) {
    switch(page) {
      case 'index':
        resetPageStack();
        break;
      case 'ReviewQuickView':
        document.body.className = page;
        visit(ReviewQuickViewPage, {review: e.data.msg_options.review});
        break;
      case 'wizard':
        document.body.className = page;
        visit(RatingForm);
        break;
      case 'RatingForm':
        visit(RatingForm);
        break;
      case 'MediaForm':
        visit(MediaForm);
        break;
      case 'BodyForm':
        visit(BodyForm);
        break;
      case 'TitleForm':
        visit(TitleForm);
        break;
      case 'AuthorForm':
        visit(AuthorForm);
        break;
      case 'SuccessPage':
        visit(SuccessPage);
        break;
      case 'empty':
        visit(EmptyPage);
        break;
      default:
        resetPageStack();
    }
  }

  var _iframe=(function(){
    window.addEventListener("message",function(e){
      switch(e.data.msg_action){
        case "ba.ratings.open_review":
          openPageOrEmpty(e, "ReviewQuickView");
          break;
        case "ba.ratings.write_review":
          openPageOrEmpty(e, "wizard");
          break;
        case "ba.ratings.modal.close":
          openPageOrEmpty(e, "empty");
          break;
        case "ba.ratings.modal.open_page":
          openPageOrEmpty(e, e.data.msg_options.page);
        case "app.started":
          if (window.preview_page){
            openPageOrEmpty(e, window.preview_page);
          } else if (window.parent.Tydal.rev_order_id){
            window.parent.postMessage({msg_action: "ba.ratings.write_review"}, '*');
          }
          break;
          console.warn("Unimplemented cmd", e);
          break;
      }
    });

    return function(msg,opts){
      window.parent.postMessage({msg_action: msg, msg_options: opts}, '*');
    };
  })();

  var _API = buildAPIClient({
    $appConfig: $revConfig,
    includeShopParamOnRequests: true
  });

  window._init = async () => {
    document.body.id = 'revModalWrapper';

    await initializeStorage(_API);

    document.body.replaceChildren(); // Clear existing content
    new App({
      target: document.body,
      context: new Map([
        ['api', _API],
        ['iframe', _iframe]
      ])
    });

    window.postMessage({ msg_action: 'app.started' });
  }
})();
