<script>
  import SealCheck from '../../common/components/icons/SealCheck.svelte';
  import ReviewStars from '../../common/components/ReviewStars.svelte';
  import ReviewVoting from '../../common/components/ReviewVoting.svelte';

  import { revConfig } from '../../config/storage';
  import { renderDate, _truncate } from '../../../util';
  import { translations } from '../../config/translations';

  function closeModal(){
    window.parent.postMessage({msg_action: "ba.ratings.modal.close", msg_options: {}}, '*');
  }

  export let review;
</script>


<div id="quickview-modal">
  <div class="review-grid-item {review.cloudflare_stream_urls[0] || review.image_urls[0] ? 'with-image' : 'no-image'}">
    <div class="review-card content-wrapper">
      {#if review.cloudflare_stream_urls[0]}
        <div class="review-media media-video" style="position: relative;">
          <iframe
            title="video"
            src="{review.cloudflare_stream_urls[0]?.iframe}"
            style="border: none; position: absolute; top: 0; height: 100%; width: 100%;"
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowfullscreen="true"
            class="cloudflare-stream-player"
          ></iframe>
        </div>
      {:else if review.image_urls[0]}
        <div class="review-media media-image"><img src="{review.image_urls[0]}" class="shown layout-vertical" alt=""></div>
      {/if}
      <div class="quickview-modal-content-wrapper">
        <div class="quickview-modal-contents {review.cloudflare_stream_urls[0] || review.image_urls[0] ? '' : ' no-image'}">
          <div class="review-header">
            <div class="author-name-w-details">
              <div class="author-name">
                {review.author}
              </div>

              {#if $revConfig.widget_settings.display_verified_badge_enabled && review.verified}
                <div class="verified-badge" title="Verified Purchase">
                  <SealCheck size=16 fill="#000000" />
                </div>
              {/if}
            </div>
          </div>

          <div class="review-subheader">
            <ReviewStars stars="{review.stars}" />
            {#if $revConfig.widget_settings.display_review_date_enabled}
              <div class="date">{renderDate(review.rated_at)}</div>
            {/if}
          </div>

          {#if review.title}
            <div class="review-title">
              {review.title}
            </div>
          {/if}

          <div class="review-body-container">
            <div class="review-body">
              {review.body}
            </div>
            {#if review.reply && review.reply_is_public}
              <hr style="margin: 1em 0; border: 1px solid;" />
              <div class="review-reply">
                <div class="review-reply-text-intro">{$translations.shop_replied.replace('{{shop_name}}', window.parent.Tydal.global_config.shop.name)}</div>
                <div class="review-reply-body">{_truncate(review.reply, 250)}</div>
              </div>
            {/if}
          </div>

          <div class="review-footer">
            {#if $revConfig.widget_settings.display_voting_enabled}
              <ReviewVoting review={review} />
            {/if}
          </div>

          <button type="button" id="rev-close-modal" class="rev-close-modal" on:click={closeModal}></button>
        </div>
      </div>
    </div>
    {#if !$revConfig.removed_tydal_branding}
      <div class="powered_by_tydal">Powered by <a aria-label="Powered by TYDAL" href="https://www.tydal.reviews/?utm_source=widget&utm_campaign=powered-by">TYDAL</a></div>
    {/if}
  </div>
</div>
