<script>
  import * as tus from 'tus-js-client';
  import { fly } from 'svelte/transition';
  import { getContext } from 'svelte';
  import { revConfig, currentProductReviewData } from '../../../config/storage';
  import { buildMsgFromTemplate } from '../../../../util';
  import { discountAmountText } from '../../../../rev_common';
  import { translations } from '../../../config/translations';
  import { saveRating } from '../../save_rating';
  import { visit } from '../../navigation';
  import BodyForm from './BodyForm.svelte';
  import RatingForm from './RatingForm.svelte';

  const api = getContext('api');

  function directUploadCloudflareStream(fileInput) {
    const file = fileInput.files[0];

    let doneResolve, doneReject;
    const done = new Promise((resolve, reject) => {
      doneResolve = resolve;
      doneReject = reject;
    });

    let uid;
    const upload = new tus.Upload(file, {
      endpoint: api.url('POST', 'cloudflare_streams/direct_uploads'),
      chunkSize: 5 * 1_024 * 1_024, // 5 MiB <https://developers.cloudflare.com/stream/uploading-videos/upload-video-file/#nodejs-example>
      metadata: {
        name: file.name,
        filename: file.name,
        filetype: file.type
      },
      onSuccess() {
        doneResolve(uid);
      },
      onError(e) {
        doneReject(e);
      },
      onAfterResponse(_req, resp) {
        uid ||= resp.getHeader('Stream-Media-ID');
      }
    });

    directUploads.push({
      upload,
      done,
      async abort() {
        try {
          await upload.abort(true);
        } catch(e) {
          return doneReject(e);
        }

        return doneReject();
      }
    });

    upload.start();

    return done;
  }

  const handleImageUpload = (event) => {
    imageFile = event.target.files[0];
    currentProductReviewData.update((data) => {
      return {
        ...data,
        image_file: imageFile
      };
    });

    saveRating({ api, draft: true }).then(() => {
      currentProductReviewData.update((data) => {
        const newData = { ...data };
        delete newData.image_file;
        return newData;
      });
    });

    setTimeout(function(){
      visit(BodyForm);
    }, 200);
  };

  const handleVideoUpload = (event) => {
    directUploads.forEach(({ abort }) => abort());
    directUploadCloudflareStream(event.target).then((uid) => {
      if (uid) {
        currentProductReviewData.update((data) => {
          return {
            ...data,
            cloudflare_stream_uid: uid
          };
        });
        saveRating({ api, draft: true });
      }
    });

    setTimeout(function(){
      visit(BodyForm);
    }, 200);
  };

  let transitionOptions = { x: 200, duration: 500 }
  export let slideBack = false;
  if (slideBack){
    transitionOptions.x = -200;
  }
  let imageFile;
  let directUploads = [];
</script>

<div id="review-wizard-modal">
  <div class="wizard-slide" data-rpoint="slide1" id="wizard-upload">
    <div class="wizard-body" in:fly="{transitionOptions}">
      <h3 class="title">{$translations.show_it_off}</h3>
      <h4 class="subtitle">{$translations.we_love_to_see_it_in_action}</h4>
      <form method="POST" action="/" enctype="multipart/form-data" class="upload-review-media" id="upload-photo-form">
        <label for="rev-upload-img" class="button rev-button">+ {$translations.choose_photo}</label>
        <input on:change={handleImageUpload} type="file" name="img" id="rev-upload-img" style="position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border-width: 0;" accept="image/x-png,image/jpeg,image/jpg">
      </form>
      {#if $revConfig.widget_settings.display_video_enabled}
        <p class="upload-review-media-alternative-separator">or</p>
        <form method="POST" action="/" enctype="multipart/form-data" class="upload-review-media" id="upload-video-form">
          <label for="rev-upload-cloudflare-stream" class="button rev-button">+ {$translations.choose_video}</label>
          <input on:change={handleVideoUpload} type="file" name="cloudflare_stream" id="rev-upload-cloudflare-stream" style="position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip: rect(0, 0, 0, 0); white-space: nowrap; border-width: 0;" accept="video/*" data-rpoint="file_cloudflare_stream">
        </form>
      {/if}
      {#if $revConfig.discount_info.discount_type && $revConfig.discount_info.discount_amount}
        <h4 class="upload-incentive-text">{buildMsgFromTemplate($translations.get_percent_off, { discount_amount: discountAmountText })}</h4>
      {/if}
    </div>
    <div class="wizard-footer">
      <button type="button" on:click={() => visit(RatingForm)} class="back bg-none">❮ {$translations.back}</button>
      <button type="button" on:click={() => visit(BodyForm)} class="skip bg-none">{$translations.skip} ❯</button>
    </div>
  </div>
</div>
