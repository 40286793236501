<script>
  import { tydal, currentProductReviewData } from '../../../config/storage';
  import { fly } from 'svelte/transition';
  import { _number_format, buildMsgFromTemplate } from '../../../../util';
  import { discountAmountText } from '../../../../rev_common';
  import { translations } from '../../../config/translations';
  import { getContext } from 'svelte';
  const iframe = getContext('iframe');

  function closeModal(){
    if ($tydal.rev_order_id){
      iframe('widget.visit', { url: `https://${$tydal.common.shop.permanent_domain}` });
    } else {
      window.parent.postMessage({msg_action: "ba.ratings.modal.close", msg_options: {}}, '*');
    }
  }

  let transitionOptions = { x: 200, duration: 500 }
  export let slideBack = false;
  if (slideBack){
    transitionOptions.x = -200;
  }
</script>

<div id="rev-finished" class="wizard-slide {$currentProductReviewData.discount_code ? 'with-discount' : ''}">
  <div class="wizard-body" in:fly="{transitionOptions}">
    <h3 class="title">{$translations.thank_you}</h3>
    {#if $currentProductReviewData.discount_code}
      <h4 class="subtitle">{buildMsgFromTemplate($translations.use_discount, { discount_amount: discountAmountText} )}</h4>
      <div class="rev-dashed-box"><p class="rev-code-size">{$currentProductReviewData.discount_code}</p></div>
    {/if}
    <button type="button" on:click={closeModal} class="button rev-button rev-wizard-close">{$translations.continue} ❯</button>
  </div>
  <div class="wizard-footer">
    <p>
      &#10003; {$translations.review_was_submitted}
    </p>
  </div>
</div>