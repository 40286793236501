<script>
  import { getContext, onMount } from 'svelte';
  import { fly } from 'svelte/transition';
  import { currentProductReviewData } from '../../../config/storage';
  import TextInput from '../../components/TextInput.svelte';
  import ErrorText from '../../components/ErrorText.svelte';
  import { translations } from '../../../config/translations';
  import { visit } from '../../navigation';
  import { saveRating, SaveRatingError } from '../../save_rating';
  import TitleForm from './TitleForm.svelte';
  import SuccessPage from './SuccessPage.svelte';

  const api = getContext('api');

  async function submit() {
    loading = true;

    try {
      await saveRating({ api });
      visit(SuccessPage);
    } catch(e) {
      if (e instanceof SaveRatingError) {
        switch (e.field) {
          case 'first_name':
            firstNameError = e.message;
            break;
          case 'email':
            emailError = e.message;
            break;
          default:
            submitError = e.message;
        }
      } else {
        throw e;
      }
    } finally {
      loading = false;
    }
  }

  onMount(() => {
    document.getElementById("first_name").focus();
  });

  let transitionOptions = { x: 200, duration: 500 }
  export let slideBack = false;
  if (slideBack){
    transitionOptions.x = -200;
  }

  let loading = false;
  let firstNameError;
  let emailError;
  let submitError;
</script>

<div class="wizard-slide" id="wizard-complete">
  <div class="wizard-body" in:fly="{transitionOptions}">
    <h3 class="title">{$translations.about_you}</h3>
    <div class="author-form">
      <div class="author-name">
        <div class="field-container">
          <TextInput id="first_name" placeholder="{$translations.first_name}" display="block"
            extraClasses={['rev-input', firstNameError ? 'error' : '']}
            on:input={() => { firstNameError = undefined }}
            bind:value={$currentProductReviewData.first_name}/>
            {#if firstNameError}
              <ErrorText id="first_name_error">{firstNameError}</ErrorText>
            {/if}
        </div>
        <div class="field-container">
          <TextInput id="last_name" placeholder="{$translations.last_name}" display="block"
            extraClasses={['rev-input']}
            bind:value={$currentProductReviewData.last_name}/>
        </div>
      </div>
      <div class="author-email">
        <div class="field-container">
          <TextInput id="email" placeholder="{$translations.email}" display="block"
            extraClasses={['rev-input', emailError ? 'error' : '']}
            on:input={() => { emailError = undefined }}
            bind:value={$currentProductReviewData.email}/>
          {#if emailError}
            <ErrorText id="email_error">{emailError}</ErrorText>
          {/if}
        </div>
      </div>
    </div>
    {#if submitError}
      <ErrorText id="submit_error">{submitError}</ErrorText>
    {/if}

    <button id="submitReview" type="button"
      on:click|preventDefault={submit} class="button rev-button {loading ? 'rev-button-loader' : ''}">
      <span class="rev-btn-text">{$translations.done}</span>
    </button>
  </div>
  <div class="wizard-footer">
    <button type="button" on:click={() => visit(TitleForm)} class="back bg-none">❮ {$translations.back}</button>
  </div>
</div>
