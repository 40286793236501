<script>
  import { onMount } from 'svelte';
  import { fly } from 'svelte/transition';
  import { currentProductReviewData } from '../../../config/storage';
  import TextInput from '../../components/TextInput.svelte';
  import { translations } from '../../../config/translations';
  import { visit } from '../../navigation';
  import AuthorForm from './AuthorForm.svelte';
  import BodyForm from './BodyForm.svelte';

  function saveRating(event){
    event.preventDefault();
    currentProductReviewData.update(data => {
      data.rating_title = ratingTitle || fallbackTitle;
      return data;
    });

    visit(AuthorForm)
  }

  let transitionOptions = { x: 200, duration: 500 }
  export let slideBack = false;
  if (slideBack){
    transitionOptions.x = -200;
  }

  onMount(() => {
    document.getElementById("rating_title").focus();
  });

  function generateFallbackTitle(){
    if (!$currentProductReviewData?.rating_body) {return '';}
    let words = $currentProductReviewData?.rating_body.split(' ');
    let firstFourWords = words.slice(0, 4).join(' ');

    return words.length > 4 ? firstFourWords + '...' : firstFourWords;
  }
  const fallbackTitle = generateFallbackTitle();
  let ratingTitle = $currentProductReviewData.rating_title || '';
</script>

<div class="wizard-slide" id="wizard-title">
  <div class="wizard-body" in:fly="{transitionOptions}">
    <h3 class="title">{$translations.review_title}</h3>
    <div class="review-title-form">
      <div class="field-container">
        <TextInput
          id="rating_title"
          display="flex"
          placeholder="{fallbackTitle}"
          extraClasses={['rev-input']}
          bind:value={ratingTitle}/>
      </div>
    </div>
    <button type="button" on:click={saveRating} class="button rev-button">{$translations.next} ❯</button>
  </div>
  <div class="wizard-footer">
    <button type="button" on:click={() => visit(BodyForm)} class="back bg-none">❮ {$translations.back}</button>
  </div>
</div>
